import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../../components/layout";

import { Footer } from "../../components/03_organisms/footer";
import { Bio } from "../../components/03_organisms/bio";
import appCoffeeIcon from "./coffee/coffee.png";
import appCoffeeOgp from "./coffee/coffee-ogp.png";
import appScreenshot from "./coffee/coffee-sc.png";
import memoScreenshot from "./coffee/memo-sc.png";
import themeCoffee from "./coffee/theme-coffee.png";
import themeMilk from "./coffee/theme-milk.png";
import themeTea from "./coffee/theme-tea.png";
import themeGreenTea from "./coffee/theme-greentea.png";
import themeCocoa from "./coffee/theme-cocoa.png";
import themeSmoothie from "./coffee/theme-smoothie.png";

import styles from "./coffeememo-style.module.css";

const CoffeeMemoLpPage = ({ data }) => {
  //  console.log(data)
  const pageTitle = "メモ帳アプリ「coffee memo」をリリース";
  const pageDescription =
    "メモ帳アプリの「coffee memo」をリリースしました。コーヒーの香りがしそうなテーマと、片手で使いやすいメモ帳を目指しています。";
  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={pageTitle}
        meta={[
          { name: "description", content: pageDescription },
          { name: "keywords", content: data.site.siteMetadata.keywords },
          { property: "og:title", content: pageTitle },
          { property: "og:description", content: pageDescription },
          { property: "og:url", content: data.site.siteMetadata.siteUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: data.site.siteMetadata.title },
          { property: "twitter:card", content: "summary" },
          {
            property: "twitter:site",
            content: data.site.siteMetadata.twitterUsername,
          },
          {
            property: "google-site-verification",
            content: data.site.siteMetadata.google_site_verification,
          },
          {
            property: "og:image",
            content: data.site.siteMetadata.siteUrl + appCoffeeOgp,
          },
          { httpEquiv: "expires", content: "43200" },
          //{ property: 'og:image', content: mushiIcon },
        ]}>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <link
          href="https://fonts.googleapis.com/css?family=Lemon&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className={`${styles.descBack} ${styles.descCoffee}`}>
        <div className={styles.descFlex}>
          <div className={styles.appName}>
            coffee
            <br />
            memo
          </div>
          <img src={appCoffeeIcon} alt="アプリアイコン" />
          <h1>メモ帳アプリ「coffee memo」をリリースしました</h1>
          <div className={styles.download}>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                className={styles.playLink}
                rel="noreferrer"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.honmushi.coffee&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Google Play で手に入れよう"
                  src="https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png"
                />
              </a>
            </div>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.playLink}
                href="https://apps.apple.com/us/app/coffee-memo/id1478383147?mt=8">
                <img
                  alt="app store"
                  width="260px"
                  src="https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2019-09-01&kind=iossoftware&bubble=ios_apps"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.descBack} ${styles.descWhite}`}>
        <div className={styles.descFlex}>
          <h3>喫茶店の雰囲気と、コーヒの香りが漂ってくるメモ帳アプリ</h3>
          <div className={styles.download}>
            <div className={styles.descImage}>
              <img src={appScreenshot} alt="シンプル機能メモ帳アプリ" />
            </div>
            <div className={styles.desc}>
              <p>
                シンプルな機能のメモ帳アプリです。タイトルをつけてメモを作成して内容を編集できます。
              </p>
              <p>
                驚くようなハイテク機能はありません。お買い物やToDoリストなどの簡単なメモを取るくらいの用途にオススメのメモ帳です。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.descBack} ${styles.descCoffee}`}>
        <div className={styles.desc}>
          <h3>片手で操作しやすいメモ帳</h3>
          <p>
            メモ画面ではいくつかのボタンを利用できます。
            <br />
            なるべくボタンを画面下部に設置して片手でも使いやすいようにしました。
          </p>
          <div className={styles.download}>
            <div className={styles.descImage}>
              <img src={memoScreenshot} alt="メモを共有できます" />
            </div>
            <div className={styles.desc}>
              <p>
                端末のクリップボードへのコピーや、他アプリへメモを共有できます。真ん中の数値は入力済みの文字数です。
              </p>
              <p>
                一番右に保存ボタンを設置しています。戻るボタンを押しても自動で保存が行われます。
              </p>
              <p>
                ゴミ箱ボタンを押すとメモを削除できます。確認メッセージが表示されるのでdeleteを選択してください。削除したデータは復元できませんのでご注意ください。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.descWhite}>
        <div className={styles.descThemeDoc}>
          <h3>6つのテーマから選択</h3>
          <p>
            メモ作成時にテーマを選択することが出来ます。以下6つのテーマがあります。お気に入りを見つけたり、用途ごとに使い分けることができます。
          </p>
        </div>
        <div className={styles.descThemeBox}>
          <div className={`${styles.theme1} ${styles.themeDiv}`}>
            <div className={styles.theme}>
              <div className={styles.descImage}>
                <img src={themeCoffee} alt="コーヒーのテーマ" />
              </div>
              <div className={styles.themeName}>coffee</div>
            </div>
            <div className={styles.theme}>
              <div className={styles.descImage}>
                <img src={themeMilk} alt="ミルクのテーマ" />
              </div>
              <div className={styles.themeName}>milk</div>
            </div>
          </div>
          <div className={`${styles.theme2} ${styles.themeDiv}`}>
            <div className={styles.theme}>
              <div className={styles.descImage}>
                <img src={themeTea} alt="紅茶のテーマ" />
              </div>
              <div className={styles.themeName}>tea</div>
            </div>
            <div className={styles.theme}>
              <div className={styles.descImage}>
                <img src={themeGreenTea} alt="グリーンティーのテーマ" />
              </div>
              <div className={styles.themeName}>green-tea</div>
            </div>
          </div>
          <div className={`${styles.theme3} ${styles.themeDiv}`}>
            <div className={styles.theme}>
              <div className={styles.descImage}>
                <img src={themeCocoa} alt="ココアのテーマ" />
              </div>
              <div className={styles.themeName}>cocoa</div>
            </div>
            <div className={styles.theme}>
              <div className={styles.descImage}>
                <img src={themeSmoothie} alt="スムージーのテーマ" />
              </div>
              <div className={styles.themeName}>smoothie</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.descBack}>
        <div className={styles.desc}>
          <h2>ダウンロードはこちらから</h2>
          <div className={styles.download}>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                className={styles.playLink}
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.honmushi.coffee&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Google Play で手に入れよう"
                  src="https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png"
                />
              </a>
            </div>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.appLink}
                href="https://apps.apple.com/us/app/coffee-memo/id1478383147?mt=8">
                <img
                  alt="app store"
                  width="260px"
                  src="https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2019-09-01&kind=iossoftware&bubble=ios_apps"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.descBack} ${styles.descWhite}`}>
        <div className={styles.desc}>
          <Bio />
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query {
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
  }
`;
export default CoffeeMemoLpPage;
